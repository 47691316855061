<template>
  <div class="paySuccess">
    <div class="loading" v-if="loading">
      <van-loading size="32px" color="#18b09f">验证订单中...</van-loading>
    </div>
    <div v-if="showwxdialog2">
      <img src="@/assets/img/finish.png" class="success" />
      <div class="tip">点击确定,返回树兰app</div>
      <div class="bottom">
        <div class="btn" @click="returnapp">
          <span>确定</span>
        </div>
      </div>
    </div>
    <div v-if="type === 'alipay'">
      <img src="@/assets/img/finish.png" class="success" />
      <div class="tip">支付成功</div>
      <div class="bottom">
        <div class="btn" @click="finish(true)">
          <span>完成</span>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="showwxdialog"
      title="请确认微信支付是否完成?"
      confirmButtonColor="#18b09f"
      confirmButtonText="已完成支付流程"
      cancelButtonText="遇到问题，重新支付"
      @confirm="payIsSuccess"
      @cancel="finish(false)"
      :showCancelButton="showCancelButton"
    >
    </van-dialog>
    <!-- <van-dialog
      v-model="showwxdialog2"
      title="点击确认返回树兰app"
      confirmButtonColor="#18b09f"
      confirmButtonText="确认"
      @confirm="returnapp"
    >
    </van-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showwxdialog2: false,
      loading: false,
      showCancelButton: true,
      showwxdialog: false,
      type: this.$route.path.indexOf("wx") > -1 ? "wx" : "alipay",
    };
  },
  methods: {
    finish(bool) {
      getParams.postMessage(
        JSON.stringify({
          pay: bool,
          clicked: true,
        })
      );
    },
    payIsSuccess() {
      this.loading = true;
      setTimeout(() => {
        this.$axios
          .get(
            `/ms-pocket-hospital/v1/pay/sccuess?payOrderId=${this.$route.query.payOrderId}`,
            { basurlType: "useslhospital" }
          )
          .then((res) => {
            console.log(res, "res");
            this.loading = false;
            if (res.data.state == 200) {
              if (res.data.results) {
                this.$dialog
                  .alert({
                    message: "订单支付成功, 点击跳转",
                    confirmButtonColor: "#18b09f",
                  })
                  .then(() => {
                    this.finish(true);
                  });
              } else {
                this.$dialog
                  .alert({
                    message: "订单未支付成功，请重新支付",
                    confirmButtonColor: "#18b09f",
                  })
                  .then(() => {
                    this.finish(false);
                  });
              }
            } else {
              if (res.data.status === 500) {
                this.$toast(res.response.data.message);
              } else {
                this.$toast(res.data.subMsg);
              }
              this.finish(false);
            }
          });
      }, 1000);
    },
    returnapp() {
      window.open("shulanuser://shulanfromHuaWei", "_self");
    },
    cancelreturn() {},
  },
  created() {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/msie ([\d.]+)/))
      ? (Sys.ie = s[1])
      : (s = ua.match(/firefox\/([\d.]+)/))
      ? (Sys.firefox = s[1])
      : (s = ua.match(/chrome\/([\d.]+)/))
      ? (Sys.chrome = s[1])
      : (s = ua.match(/opera.([\d.]+)/))
      ? (Sys.opera = s[1])
      : (s = ua.match(/version\/([\d.]+).*safari/))
      ? (Sys.safari = s[1])
      : 0;
    console.log("IE: " + Sys.ie);
    console.log("Firefox: " + Sys.firefox);
    console.log("Chrome: " + Sys.chrome);
    console.log("Opera: " + Sys.opera);
    console.log("Safari: " + Sys.safari);
    if (Sys.safari && !Sys.chrome) {
      this.showwxdialog2 = true;
      // window.open("shulanuser://shulanfromHuaWei");
    } else {
      if (this.type === "wx") {
        this.showwxdialog = true;
      }
    }
    // if (this.type === "wx") {
    //   this.showwxdialog = true;
    // }
  },

  // 使用钩子 手动修改document.body.style
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F8F9FA");
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.paySuccess {
  .loading {
    width: 100%;
    position: absolute;
    top: 40%;
    text-align: center;
  }
  width: 100%;
  background: #fff;
  font-size: 40px;
  color: #4a4a4a;
  padding: 100px 0 60px;
  text-align: center;
  .success {
    height: 180px;
  }
  .tip {
    margin-top: 40px;
    font-size: 40px;
    color: #4a4a4a;
  }
  .bottom {
    margin-top: 160px;
    padding: 0 20px;
  }
  .btn {
    box-sizing: border-box;
    width: 100%;
    height: 90px;
    line-height: 90px;
    font-size: 36px;
    color: #ffffff;
    border-radius: 10px;
    background: #19b09c;
    letter-spacing: 8px;
  }
}
</style>
